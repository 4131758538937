import {
  Box, Container, Text,
  useColorMode
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {useStaticQuery,graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import NewsListItem from "../components/NewsListItem";
import QuadKachelnInvRelations from "../components/QuadKachelnInvRelations";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";


const News = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });

  const data = useStaticQuery(graphql`
  query getResearch {
    allWpResearch(sort: {fields: date, order: DESC}) {
      nodes {
        researchPDF {
          localFile {
            relativePath
          }
        }
        researchDate
        titleResearch
      }
    }
  }
  
   `);
   const DIDDS = data.allWpResearch.nodes;

  return (
    <>
      <Helmet>
        <title>tokentus | Research</title>
        <meta name="description" content="Whitepapers and Case Studies featuring tokentus investment AG and the company’s development. " />
        <link rel="canonical" href="https:/tokentus.com/research" />
      </Helmet>
    


      <ImgTextModule
        src={"Research.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="75vh"
        mediaOpacity={0.9}
        bgColor="brand.black"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={8}>



          <Box p={{base:0,lg:'8'}}>
          <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="white" fontWeight="normal" fontSize="xl">
             Learnings and Innovations
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="brand.green" fontWeight="normal"   fontSize={{ base: "5xl", lg: "6xl" }}>
                Research.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.white"
                mb={4}
                maxW={{ base: "100%", lg: "50%" }}
                fontSize="2xl"
              >
               Whitepapers and Case Studies.
              </Text>
          
            </FadeInAnimation>
          </Box>


          </Box>
        </Container>
      </ImgTextModule>
      {/* NEWS */}

      <Box w="100%">
       
        <Container variant="layoutContainer" px={{ base: 0, lg: 10 }} py={10}>
        {DIDDS.map((News)=>(  
      <>

     
               <NewsListItem
               datum={News.researchDate}
               textinhalt={News.titleResearch}
                pdflink={News.researchPDF.localFile.relativePath}
                       /> 
  
         
</>
  ))
}
        </Container>

      <QuadKachelnInvRelations />
      </Box>
    </>
  );
};

export default News;
